const passwordWrappers = document.querySelectorAll(".password-wrapper");

const isNotEmpty = passwordWrappers.length > 0;

if(isNotEmpty) {
  passwordWrappers.forEach((passwordWrapper) => {
    const toogleReveal = passwordWrapper.querySelector(".toggle-display-pw");
    const eye = passwordWrapper.querySelector(".eye");
    const eyeClosed = passwordWrapper.querySelector(".eye-close");
    const passwordInput = passwordWrapper.querySelector(".password_input");

    let reveal = false;

    toogleReveal.addEventListener("click", () => {
      reveal = !reveal;
      passwordInput.type = reveal ? "text" : "password";

      if (reveal) {
        eye.classList.add("hide");
        eyeClosed.classList.remove("hide");
      } else {
        eye.classList.remove("hide");
        eyeClosed.classList.add("hide");
      }
    });
  });
}
